import { Button } from "react-bootstrap"


function GeneratedExercise(props){
    async function add(){
        await props.AddFunction(props.correctAnswer, props.incorrectAnswer, props.key)

    }
    return <li><span style={{marginRight: "10px"}}>{props.correctAnswer} / {props.incorrectAnswer}</span><Button color="secondary" style={{margin: "5px"}} onClick={add}>Add</Button></li>
}

export default GeneratedExercise