import logo from './logo.svg';
import './App.css';
import MyNavbar from './MyNavbar';
import Test from './Pages/Test';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home'
import AppRouter from './AppRouter';
import NoDevtools from './NoDevtools';
import EasterEgg from './EasterEgg';

function App() {
  NoDevtools();
  return <div>
    <MyNavbar/>
    <AppRouter/>
    <EasterEgg/>
  </div>  
}

export default App;
