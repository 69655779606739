import { useState, useTransition, useEffect } from 'react';
import { Form, Button, Collapse, Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../App.css';
import vancura from '../assets/vancura.png'
import RefreshTokenIfNeeded from '../RefreshTokenIfNeeded'
import './Modify.css'
import Cookies from 'universal-cookie';
import GeneratedExercise from './GeneratedExercise';
const cookies = new Cookies();

function Exercise(props) {
  const [questions, setQuestions] = useState([])
  const [exercise, setExercise] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [showQuestionConfirmation, setShowQuestionConfirmation] = useState(false)
  const handleCloseQuestionConfirmation = () => setShowQuestionConfirmation(false);
  const handleShowQuestionConfirmation = () => setShowQuestionConfirmation(true);

  async function SubmitForm(e){
    const formData = new FormData(e.currentTarget);
    const questionIds = JSON.parse(exercise.questions)
    e.preventDefault()
    console.log(questionIds)
    for(var i = 0;i<questionIds.length;i++){
        let correctOption = formData.get("question"+i+"correctOption") == "on" ? 1 : 0
        let body = JSON.stringify({
            option0: formData.get("question"+i+"option0"),
            option1: formData.get("question"+i+"option1"),
            correctOption: correctOption
        })
        console.warn(correctOption)
        RefreshTokenIfNeeded()
        let response = await fetch(process.env.REACT_APP_API+"updateQuestion/"+questionIds[i], {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+cookies.get("accessToken")
          },
          body: body
        })
    }
    setSuccess(true)
    setTimeout(() => {
        setSuccess(false)
    }, 5000)
  }

  useEffect(() => {
    //! ziskat otazky
    console.log("A")
    if(exercise){
      (async () => {
        console.log(exercise.questions)
        let fetches = JSON.parse(exercise.questions).map(async question => {
          RefreshTokenIfNeeded()
          return fetch(process.env.REACT_APP_API+"questionWithAnswers/"+question, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+cookies.get("accessToken")
            },
          })
        })
        let responses = await Promise.all(fetches)
        let questionss = await Promise.all(responses.map(res => res.json()))
        setQuestions(questionss)
      })()
    }
  }, [exercise]);

  useEffect(() => {
    (async () => {
      console.log("efekt pro exercise fetch")
      let response = await fetch(process.env.REACT_APP_API+"exercise/"+props.id)
      setExercise(await response.json())
      console.log("exerciose fetch end")
    })()
  }, [])

  const AddQuestion = async (reload = true) => {
    RefreshTokenIfNeeded()
    let response = await fetch(process.env.REACT_APP_API+"createEmptyQuestion", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+cookies.get("accessToken")
      }
    })
    let newQuestionId = await response.text()

    const questionIds = JSON.parse(exercise.questions)
    questionIds.push(newQuestionId)

    let body = JSON.stringify({
      questions: JSON.stringify(questionIds),
    })
    RefreshTokenIfNeeded()
    let res2 = await fetch(process.env.REACT_APP_API+"updateExercise/"+props.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+cookies.get("accessToken")
      },
      body: body
    })
    if(reload){
      document.location.reload()
    }else return newQuestionId
  }

  let [askButtonText, setAskButtonText] = useState("Prompt Gemini")
  let [askButtonDisabled, setAskButtonDisabled] = useState(undefined)

  const AskGemini = async (e) => {
    e.preventDefault()
    setAskButtonDisabled(true)
    setAskButtonText("Please wait...")
    const formData = new FormData(e.currentTarget);

    let check = await fetch(process.env.REACT_APP_API+"geminiGenerate/"+props.id, {
      headers: {
          "Authorization": "Bearer "+cookies.get("accessToken"),
          "Content-Type": "application/json",
      },
      body: JSON.stringify({input: formData.get("prompt"), model: formData.get("model")}),
      method: "POST"
  })
  setGeminiQuestions(await check.json())
  setAskButtonDisabled(false)
  setAskButtonText("Prompt Gemini")
  }

  const AddGeminiQuestion = async (correctAnswer, incorrectAnswer, idx) => {
    const newQuestionId = await AddQuestion(false)

    let option0 = correctAnswer
    let option1 = incorrectAnswer
    let correctOption = 0

    if(Math.random() > 0.5){ // 50 / 50
      let temp = option0
      option0 = option1
      option1 = temp
      correctOption = 1
    }

    let body = JSON.stringify({
      option0,
      option1,
      correctOption
  })

  RefreshTokenIfNeeded()
  let response = await fetch(process.env.REACT_APP_API+"updateQuestion/"+newQuestionId, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+cookies.get("accessToken")
    },
    body: body
  })

    const newQuestions = JSON.parse(JSON.stringify(questions))
    newQuestions.push({id: newQuestionId, option0, option1, correctOption})
    setQuestions(newQuestions)

    const newGeminiQuestions = JSON.parse(JSON.stringify(geminiQuestions))
    console.log(idx)
    newGeminiQuestions.splice(idx, 1)
    setGeminiQuestions(newGeminiQuestions)
  }

  let [geminiQuestions, setGeminiQuestions] = useState([])

  return (
    <div className="content">
      {exercise && <h1>{exercise.nadpis}</h1>}
      <Form onSubmit={SubmitForm}>
        {questions.map((question, idx) => {
          console.log(question, idx)
          return <div key={"question"+idx}>
            <div className={"questionNumber "+(idx+1<10 ? "questionNumberBig" : "questionNumberSmall")}>
              <a>{idx+1}</a>
            </div>
            <Form.Control
                type="text"
                placeholder="First question"
                size="sm"
                defaultValue={question.option0}
                name={"question"+idx+"option0"}
                autoComplete="off"
            />
            <Form.Control
                type="text"
                placeholder="Second question"
                size="sm"
                defaultValue={question.option1}
                name={"question"+idx+"option1"}
                autoComplete="off"
            />
            <Form.Check 
              type="switch"
              name={"question"+idx+"correctOption"}
              label="Second answer correct (otherwise first answer)"
              defaultChecked={question.correctOption==1}
            />
            <br/>
          </div>
        })}
        <Button variant="primary" type="submit" disabled={questions.length > 0 ? questions[0].selected != undefined : false}>
          Update
          </Button>  
          <Button type="button" variant='secondary' onClick={handleShowQuestionConfirmation}>
            Add question
            </Button>
      </Form>
      <br/>
      <Collapse in={success}>
        <div>
          {/* <img src={vancura}/> */}
      
           <Alert key="success" variant="success">
              Successfully changed.
            </Alert> 
        </div>
      </Collapse>
      <br></br>
      <h1>AI Generation</h1>
      <a>Use Google's Gemini AI to create questions.</a><br/><br/>
      <h3>Guide</h3>
      <a>Firstly, save your questions, then prompt gemini to create questions, add the questions that you like (the questions will be randomly swapped whether the first answer is correct or the second) and then you can prompt again. The gemini generated questions will be automatically saved.
        <br/>If Gemini is returning only one question, try multiple times and if that doesn't work you can specify for example "past perfect 20 questions".
        <br/>Please note that not all models will work.</a><br/><br/>
      <Form onSubmit={AskGemini}>
        <Form.Select
          name="model"
          defaultValue="gemini-1.5-flash"
        >
          <option value="gemini-1.0-pro">Gemini 1.0 Pro</option>
          <option value="gemini-1.0-pro-vision-latest">Gemini 1.0 Pro Vision</option>
          <option value="gemini-1.0-pro-002">Gemini 1.0 Pro 002</option>
          <option value="gemini-1.5-pro">Gemini 1.5 Pro</option>
          <option value="gemini-1.5-pro-exp-0827">Gemini 1.5 Pro Experimental 0827</option>
          <option value="gemini-1.5-flash">Gemini 1.5 Flash</option>
          <option value="gemini-1.5-flash-exp-0827">Gemini 1.5 Flash Experimental 0827</option>
          <option value="gemini-1.5-flash-exp-0827">Gemini 1.5 Flash 8B Experimental 0827</option>
          <option value="gemini-1.5-flash-002">Gemini 1.5 Flash 002</option>
          <option value="gemma-2-2b-it">Gemma 2 2B</option>
          <option value="gemma-2-9b-it">Gemma 2 9B</option>
          <option value="gemma-2-27b-it">Gemma 2 27B</option>
        </Form.Select><br/>
            <Form.Control
                type="text"
                placeholder="Prompt"
                size="sm"
                name="prompt"
                autoComplete="off"
            /><br/>
        <Button  type="submit" disabled={askButtonDisabled}>{askButtonText}</Button>
      </Form><br/>
      <ul>
        {geminiQuestions.map((value, idx) => <GeneratedExercise correctAnswer={value.correctAnswer} incorrectAnswer={value.incorrectAnswer} key={idx} AddFunction={AddGeminiQuestion}></GeneratedExercise>)}
      </ul>
      <Modal show={showQuestionConfirmation} onHide={handleCloseQuestionConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Adding a question will not save changes!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuestionConfirmation}>
            Close
          </Button>
          <Button variant="primary" onClick={AddQuestion}>
            Add anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Exercise;
