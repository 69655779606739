import MyNavbar from './MyNavbar';
import Test from './Pages/Test';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home'
import Login from './Pages/Login'
import ModifyExercise from './Pages/ModifyExercise'
import Admin from './Pages/Admin';
import ExercisePage from './Pages/ExercisePage';

function AppRouter(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/test" element={<Test/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/admin/modify/:id" element={<ModifyExercise/>} />
                <Route path="/admin" element={<Admin/>} />
                <Route path="/exercise/:id" element={<ExercisePage/>} />
            </Routes>
        </BrowserRouter>
      )
}


export default AppRouter;
