import { useState, useTransition, useEffect } from 'react';
import { Form, Button, Collapse } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../App.css';
import vancura from '../assets/vancura.png'

function Exercise(props) {
  const [questions, setQuestions] = useState([])
  const [exercise, setExercise] = useState(undefined)

  async function SubmitForm(e){
    const formData = new FormData(e.currentTarget);
    const questionIds = JSON.parse(exercise.questions)
    e.preventDefault()
    let body = JSON.stringify({questions: questions.map((question, idx) => {
      return {
        id: questionIds[idx],
        answer: formData.get("question"+idx)==null ? "0" : formData.get("question"+idx)
      }})})
    let response = await fetch(process.env.REACT_APP_API+"checkAnswers", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: body
    })
    let data = await response.json();
    console.log(data)
    setQuestions(questions.map((question, idx) => {
      //question.correct = data[idx] ? Number(formData.get("question"+idx)) : (formData.get("question"+idx) == 1 ? 0 : 1)
      question.correct = data[idx]
      question.selected = Number(formData.get("question"+idx))
      return question
    }))
  }

  useEffect(() => {
    //! ziskat otazky
    console.log("A")
    if(exercise){
      (async () => {
        console.log(exercise.questions)
        let fetches = JSON.parse(exercise.questions).map(async question => {
          return fetch(process.env.REACT_APP_API+"question/"+question)
        })
        let responses = await Promise.all(fetches)
        let questionss = await Promise.all(responses.map(res => res.json()))
        setQuestions(questionss)
      })()
    }
  }, [exercise]);

  useEffect(() => {
    (async () => {
      console.log("efekt pro exercise fetch")
      let response = await fetch(process.env.REACT_APP_API+"exercise/"+props.id)
      setExercise(await response.json())
      console.log("exerciose fetch end")
    })()
  }, [])
  const correctQuestions = questions.reduce((prev, curr, idx) => {
    return curr.correct ? prev + 1 : prev
  }, 0)
  return (
    <div className="content">
      {exercise && <h1>{exercise.nadpis}</h1>}
      <Form onSubmit={SubmitForm}>
        {questions.map((question, idx) => {
          console.log(question, idx)
          return <div key={"question"+idx}>
            <div className={"questionNumber "+(idx+1<10 ? "questionNumberBig" : "questionNumberSmall")}>
              <a>{idx+1}</a>
            </div>
            <Form.Check
              type="radio"
              id={"question"+idx+"answer0"}
              label={question.option0}
              name={"question"+idx}
              value="0"
              isValid={question.correct ? question.selected == 0 : question.selected == 1}
              isInvalid={question.correct ? question.selected == 1 : question.selected == 0}
              disabled={question.selected != undefined}
            />
            <Form.Check
              type="radio"
              id={"question"+idx+"answer1"}
              label={question.option1}
              name={"question"+idx}
              value="1"
              isValid={question.correct ? question.selected == 1 : question.selected == 0}
              isInvalid={question.correct ? question.selected == 0 : question.selected == 1}
              disabled={question.selected != undefined}
            />
            <br/>
          </div>
        })}
        <Button variant="primary" type="submit" disabled={questions.length > 0 ? questions[0].selected != undefined : false}>Submit</Button>
      </Form>
      <br/>
      <Collapse in={questions.length > 0 ? questions[0].selected != undefined : false}>
        <div>
          {/* <img src={vancura}/> */}
      
           <Alert key="info" variant="info">
              Correct answers: {correctQuestions}/{questions.length}<br/>
              Your score is {Math.round(correctQuestions/questions.length*100)}%
            </Alert> 
        </div>
      </Collapse>
      <br></br>
    </div>
  );
}

export default Exercise;
