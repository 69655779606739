import '../App.css';

function Test() {
  return (
    <div>
        <h1 className="mainH1">English Workout</h1>
    </div>
  );
}

export default Test;
