
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import vancura from './assets/vancura.png'

function EasterEgg(){
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    let typed = ""
    document.onkeydown = function(e) { 
        typed += e.key
        if(typed.endsWith("dykobraz")){
            handleShow()
        }
    } 
    
    return <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
        <Offcanvas.Title>DYKOBRAZ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <img src={vancura}/>
        </Offcanvas.Body>
  </Offcanvas>
}

export default EasterEgg;