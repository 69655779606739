import { useState, useEffect } from 'react';
import { Form, Button, Collapse, Card, Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../App.css';
import vancura from '../assets/vancura.png'
import Modify from '../exercise/Modify'
import {useParams} from "react-router-dom";
import Cookies from 'universal-cookie';
import RefreshTokenIfNeeded from '../RefreshTokenIfNeeded';
const cookies = new Cookies();



function Admin() {
    let [exercises, setExercises] = useState([])
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addExercise = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget);

        const questionNumber = formData.get("questions")
        let questionIds = []

        for(var i = 0;i<questionNumber;i++){
            RefreshTokenIfNeeded()
            let response = await fetch(process.env.REACT_APP_API+"createEmptyQuestion", {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+cookies.get("accessToken")
                }
            })
            questionIds.push(await response.text())
        }

        let body = JSON.stringify({
            nadpis: formData.get("nadpis"),
            questions: JSON.stringify(questionIds),
        })
        
        RefreshTokenIfNeeded()
        let response = await fetch(process.env.REACT_APP_API+"createExercise", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+cookies.get("accessToken")
            },
            body: body
        })

        document.location.href = "/admin/modify/"+await response.text()
    }

    useEffect(() => {
    (async () => {
        let response = await fetch(process.env.REACT_APP_API+"exerciseList")
        setExercises(await response.json())
    })()
    }, [])
    return (
        <div className="content">
            <Button variant="primary" onClick={handleShow}>Add exercise</Button><br/><br/>
            <div>
                {exercises.map((value) => <Card>
                    <Card.Body>
                        <Card.Title>{value.nadpis}</Card.Title>
                        <Card.Text>
                            Exercise {value.nadpis}<br/>
                            {JSON.parse(value.questions).length} questions
                        </Card.Text>
                        <Button variant="primary" onClick={() => {document.location.href = "/admin/modify/"+value.id}}>Edit exercise</Button>
                    </Card.Body>
                </Card>)}
            </div>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={addExercise}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add exercise</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="addExercise.nadpis">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="nadpis" required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="addExercise.questions">
                            <Form.Label>Number of questions</Form.Label>
                            <Form.Control type="number" name="questions" defaultValue="10" required/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Create exercise
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}

export default Admin;
