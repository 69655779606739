import { useState } from 'react';
import { Form, Button, Collapse } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../App.css';
import vancura from '../assets/vancura.png'
import Exercise from '../exercise/Exercise'



function Test() {
  return (
    <div>
      <Exercise 
        id="1"
      />
    </div>
  );
}

export default Test;
